import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container } from "reactstrap"
import Header from "./header/header"
import "./styles.css"
import "./Contact-Form-Clean.css"
import "./Navigation-Clean-1.css"
import "./Navigation-Clean-2.css"
import "./Navigation-with-Button.css"
import "bootstrap/dist/css/bootstrap.css"
import "./font-face.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Container fluid={true} className="p-0">
        <main>{children}</main>
        <div id="footer" className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12 align-self-center">
                <small>© 2019 Nelson Paralegal. All rights reserved.</small>
              </div>
              <div className="col-md-6 col-sm-12 text-right">
                <small>
                  Designed and Powered by&nbsp;
                  <a
                    href="https://webriq.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    WebriQ
                  </a>
                </small>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
