import React from "react"
import { Link } from "gatsby"
// import Sticky from "react-sticky-el"
// import SideBarMenu from "../sidebar/sidebar"
// import styl from "./header.module.css"
import logo from "../assets/img/LOGO.png"

const Header = () => (
  <header>
    {/* <Sticky
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: "unset", zIndex: "2" }}
    > */}
    <div id="header" class="container-fluid py-3 px-5">
      <ul class="list-unstyled list-inline align-self-center mb-0">
        <li class="list-inline-item align-self-center logo-wrap">
          <div class="logo-div">
            <Link to="/" class="logo">
              <img src={logo} class="img-fluid" alt="Logo" />
            </Link>
          </div>
        </li>
        <li class="list-inline-item align-self-center">
          <ul id="nav-link" class="list-unstyled list-inline">
            <li class="list-inline-item">
              <Link to="#services">Services</Link>
            </li>
            <li class="list-inline-item">
              <Link to="#howTHY">How to</Link>
            </li>
            <li class="list-inline-item">
              <Link to="#about-us">About us</Link>
            </li>
            <li class="list-inline-item">
              <Link to="#contact">Contact</Link>
            </li>
            <li class="list-inline-item contact-num-head px-3 py-2">
              <a href="tel:(011)-681-8238">
                <i class="fa fa-volume-control-phone mr-2 fa-lg"></i>
                (011)-681-8238
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    {/* </Sticky> */}
  </header>
)

export default Header
